import CardExplicit from "./CardExplicitRound"
import data from "./projects-data"

export default function Projects() {
	const cards = data.map(item => {
		return (
			<CardExplicit
				key={item.id}
				{...item}
			/>
		)
	})
	return (
		<div className="ProjectsExplicit">
			<center><h1 className="projects-explicit--title">Projects</h1></center>
			<section className='cards-list-explicit'>
				{cards}
			</section>
		</div>

	);
}
