import CardCompact from "./CardCompact"
import data from "./projects-data"

export default function Projects() {
	const cards = data.map(item => {
		return (
			<CardCompact
				key={item.id}
				{...item}
			/>
		)
	})
	return (
		<div className="ProjectsCompact">
			<section className='cards-list-compact'>
				{cards}
			</section>
		</div>

	);
}
