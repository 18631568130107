import { useState } from 'react'
import CardPlank from "./CardPlank"
import data from "./projects-data"
import imgA from "../images/react-tag.png"
import imgB from "../images/react-logo.png"
import css from "../images/stack/CSS.png"
import html from "../images/stack/HTML.png"
import js from "../images/stack/JavaScript.png"
import lua from "../images/stack/Lua.png"
import opencv from "../images/stack/OpenCV.png"
import playdate from "../images/stack/Playdate.png"
import python from "../images/stack/Python.png"
import react from "../images/stack/React.png"

export default function Projects() {
	const [stackImg, setStackImg] = useState(false);
	const [filter, setFilter] = useState([]);
	
	let opacitySelected = '1';
	let opacityDefault = '0.3';

	const changeStackLook = () => {
		setStackImg(stackImg? false : true);
	}

	const updateFilter = event => {
		let newFilter = event.target.id
		if (filter.includes(newFilter)) {
			let tempFilter = filter.slice()
			tempFilter.splice(tempFilter.indexOf(newFilter), 1)
			setFilter(tempFilter)
			updateFilterItemOpacity(event, opacityDefault)
		}
		else {
			updateFilterItemOpacity(event, opacitySelected)
			setFilter([...filter, event.target.id])
		}
	}
	
	const resetFilter = () => {
		setFilter([])
		let parent = document.getElementsByClassName('projects-plank--filter--list')
		for (let i = 0; i < parent.length; i++) {
			for (let j = 0; j < parent[i].children.length; j++) {
				parent[i].children[j].style.opacity = opacityDefault;
			}
		}
	}

	const updateFilterItemOpacity = (event, opacity) => {
		let elements = document.getElementsByClassName(event.target.classList[1])
		for (let i = 0; i < elements.length; i++) {
			elements[i].style.opacity = opacity;
		}
	}

	const cards = data.map(item => {
		const id = item.id;

		return (
			<CardPlank
				key={id}
				showStackImg = {stackImg}
				showTech = {filter}
				{...item}
			/>
		)
	})

	function projectsTitle(show) {
		return (
			show && <center><h1 className="projects-plank--title">Projects</h1></center>
		)
	}

	return (
		<div className="ProjectsPlank">
			{projectsTitle(false)}
			<div className='projects-plank--filter-section'>
				<button className='projects-plank--button--change-style' onClick={changeStackLook}>
					<img src={imgA} className={`${stackImg ? 'projects-plank--button--change-style--icon-back' : 'projects-plank--button--change-style--icon-front'}`} />
					<img src={imgB} className={`${stackImg ? 'projects-plank--button--change-style--icon-front' : 'projects-plank--button--change-style--icon-back' }`} />
				</button>
				<div className='projects-plank--filter'>
					<div className='projects-plank--filter--list' style={{ display: `${stackImg ? 'none' : 'flex'}`}}>
						<span className='projects-plank--filter--list-item filter-css' id='CSS' onClick={updateFilter}>CSS</span>
						<span className='projects-plank--filter--list-item filter-html' id='HTML' onClick={updateFilter}>HTML</span>
						<span className='projects-plank--filter--list-item filter-js' id='JavaScript' onClick={updateFilter}>JavaScript</span>
						<span className='projects-plank--filter--list-item filter-lua' id='Lua' onClick={updateFilter}>Lua</span>
						<span className='projects-plank--filter--list-item filter-opencv' id='OpenCV' onClick={updateFilter}>OpenCV</span>
						<span className='projects-plank--filter--list-item filter-pd' id='Playdate' onClick={updateFilter}>Playdate</span>
						<span className='projects-plank--filter--list-item filter-py' id='Python' onClick={updateFilter}>Python</span>
						<span className='projects-plank--filter--list-item filter-react' id='React' onClick={updateFilter}>React</span>
					</div>
					<div className='projects-plank--filter--list' style={{ display: `${stackImg ? 'flex' : 'none' }`}}>
						<img src={css} className='projects-plank--filter--list-icon filter-css' id='CSS' onClick={updateFilter} />
						<img src={html} className='projects-plank--filter--list-icon filter-html' id='HTML' onClick={updateFilter} />
						<img src={js} className='projects-plank--filter--list-icon filter-js' id='JavaScript' onClick={updateFilter} />
						<img src={lua} className='projects-plank--filter--list-icon filter-lua' id='Lua' onClick={updateFilter} />
						<img src={opencv} className='projects-plank--filter--list-icon filter-opencv' id='OpenCV' onClick={updateFilter} />
						<img src={playdate} className='projects-plank--filter--list-icon filter-pd' id='Playdate' onClick={updateFilter} />
						<img src={python} className='projects-plank--filter--list-icon filter-py' id='Python' onClick={updateFilter} />
						<img src={react} className='projects-plank--filter--list-icon filter-react' id='React' onClick={updateFilter} />
					</div>
				</div>
				<h2 className='projects-plank--filter-reset' style={{ opacity: `${filter.length > 0 ? opacitySelected : '0.2'}`, cursor: `${filter.length > 0 ? 'pointer' : 'default' }`}} onClick={resetFilter}>RESET<br />FILTER</h2>
			</div>
			<section className='cards-list-plank'>
				{cards}
			</section>
		</div>

	);
}
