function Content() {
	return (
		<div className="content-container">
		<center><h3 className="content--heading">Lorem Ipsum</h3></center>
		<p className="content--text">
		Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
		Viverra nam libero justo laoreet sit. Neque sodales ut etiam sit amet nisl purus in mollis. Netus et malesuada fames ac turpis egestas. Arcu ac tortor dignissim convallis aenean et tortor at risus. Vitae semper quis lectus nulla at volutpat. Quis imperdiet massa tincidunt nunc pulvinar sapien et ligula ullamcorper. Nullam vehicula ipsum a arcu cursus vitae congue mauris rhoncus. Eu nisl nunc mi ipsum faucibus vitae aliquet nec ullamcorper. Egestas fringilla phasellus faucibus scelerisque eleifend donec pretium vulputate sapien. Neque sodales ut etiam sit amet nisl purus in. Purus sit amet volutpat consequat mauris nunc congue nisi vitae. Quis blandit turpis cursus in hac habitasse platea dictumst. Amet nisl purus in mollis nunc sed id semper. Scelerisque mauris pellentesque pulvinar pellentesque habitant morbi. Iaculis urna id volutpat lacus laoreet non curabitur gravida arcu. Turpis massa tincidunt dui ut ornare lectus sit amet est. Tincidunt dui ut ornare lectus. Sed nisi lacus sed viverra tellus in hac habitasse. Odio euismod lacinia at quis.

Platea dictumst quisque sagittis purus sit amet volutpat consequat. Pellentesque dignissim enim sit amet venenatis urna cursus eget nunc. Hac habitasse platea dictumst quisque sagittis. Ut porttitor leo a diam. Condimentum id venenatis a condimentum vitae sapien pellentesque. Sed egestas egestas fringilla phasellus faucibus scelerisque eleifend donec pretium. Amet consectetur adipiscing elit ut aliquam purus sit. Nisl suscipit adipiscing bibendum est ultricies integer quis auctor. Urna neque viverra justo nec ultrices dui sapien eget. Sollicitudin tempor id eu nisl nunc mi. Lectus proin nibh nisl condimentum id venenatis a condimentum. Quam id leo in vitae turpis massa sed elementum. Volutpat blandit aliquam etiam erat velit scelerisque.

Est placerat in egestas erat imperdiet sed euismod. Vel orci porta non pulvinar neque. Ut consequat semper viverra nam. Tellus molestie nunc non blandit massa enim nec dui nunc. Eu mi bibendum neque egestas congue quisque egestas diam in. Aliquet eget sit amet tellus. Pretium viverra suspendisse potenti nullam ac tortor vitae purus faucibus. Sit amet nulla facilisi morbi tempus iaculis. Arcu bibendum at varius vel pharetra vel turpis nunc. Nisl tincidunt eget nullam non. Et ultrices neque ornare aenean euismod elementum nisi. Consequat nisl vel pretium lectus quam id leo in vitae.

Fermentum leo vel orci porta non pulvinar. Tortor dignissim convallis aenean et. Eget magna fermentum iaculis eu non diam phasellus. Dui accumsan sit amet nulla facilisi morbi tempus. Malesuada bibendum arcu vitae elementum curabitur vitae nunc. Quam nulla porttitor massa id neque aliquam. Volutpat lacus laoreet non curabitur gravida arcu ac. Ornare massa eget egestas purus viverra accumsan in. Mollis nunc sed id semper risus in hendrerit gravida rutrum. Eleifend mi in nulla posuere sollicitudin aliquam. Proin nibh nisl condimentum id venenatis a. Sed risus ultricies tristique nulla aliquet enim tortor at. Ut etiam sit amet nisl purus in mollis nunc. A erat nam at lectus urna. Cras adipiscing enim eu turpis egestas pretium aenean pharetra magna. Quis eleifend quam adipiscing vitae proin. Vulputate eu scelerisque felis imperdiet proin fermentum leo vel. Ullamcorper sit amet risus nullam eget felis eget nunc lobortis. Ullamcorper morbi tincidunt ornare massa eget. Nunc lobortis mattis aliquam faucibus purus in massa tempor nec.

Cursus turpis massa tincidunt dui. In massa tempor nec feugiat nisl pretium fusce id. Pellentesque nec nam aliquam sem et tortor. Malesuada fames ac turpis egestas integer eget aliquet nibh. Fermentum odio eu feugiat pretium nibh ipsum. Id eu nisl nunc mi. Blandit cursus risus at ultrices mi tempus imperdiet nulla malesuada. Amet tellus cras adipiscing enim eu turpis egestas. Pharetra vel turpis nunc eget lorem dolor sed. Egestas diam in arcu cursus euismod. Vitae nunc sed velit dignissim sodales ut eu. Sit amet commodo nulla facilisi nullam vehicula. Tellus in hac habitasse platea dictumst vestibulum rhoncus est pellentesque. Duis ultricies lacus sed turpis tincidunt id aliquet. Tristique sollicitudin nibh sit amet commodo. Et egestas quis ipsum suspendisse. Egestas diam in arcu cursus euismod quis viverra nibh cras.
		</p>
		<hr />
		<center>*</center>
		<br />
		</div>
	);
}

export default Content;
