export function getQuicksortAnimation(array) {
	const animation = [];
	quickSort(array, animation);
	return animation;
}

function partition(array, animation, start, end) {
	const pivotValue = array[start];
	animation.push(['pivot', start, end]);
	animation.push(['partition', start, end]);
	let swap = start;
		for (let i = start + 1; i <= end; i++) {
			if (pivotValue > array[i]) {
				swap++;
				if (i !== swap) {
					[array[i], array[swap]] = [array[swap], array[i]];
					animation.push(['swap', i, swap]);
				}
			}
		}
	if (swap !== start) {
		[array[swap], array[start]] = [array[start], array[swap]];
		animation.push(['swap', start, swap]);
	}
	return swap
}

function quickSort(array, animation, start = 0, end = array.length - 1) {
	if (start >= end) return;

	let pivot = partition(array, animation, start, end);

	quickSort(array, animation, start, pivot - 1);
	quickSort(array, animation, pivot + 1, end);
}
