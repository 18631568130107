import { useState } from 'react'
import { Link } from "react-router-dom"
import web from '../images/web-black.png'
import github from '../images/code-black.png'
export default function Card(props) {
	let hasWeb
	let hasLocal
	let showCard = true
	const [stackImg, setStackImg] = useState(false);

	if (props.links.web) {
		hasWeb = true
	} else {
		hasWeb = false
	}

	if (props.links.local) {
		hasLocal = true
	} else {
		hasLocal = false
	}


	let stack = props.stack

	if (props.showTech.length > 0) {
		for (let i = 0; i < stack.length; i++) {
			if (!props.showTech.includes(stack[i])){
				showCard = false;
			} else {
				showCard = true;
				break
			}

		}
	}

	const getStack = stack.map(item => {
		if (props.showStackImg) {
			return (
				<img src={`./images/stack/${item}.png`} className='card-plank--tech-item--image' />
			);
		} else { 
			return <span className='card-plank--tech-item' style={{backgroundColor:`${props.theme.stack}`, color:`${props.theme.stackFont}`}}>{`${item}`}</span>;
		}
	});



	const changeStackLook = () => {
		setStackImg(stackImg? false : true);
	}

	if (showCard) {
		return (
			<div className='CardPlank' key={props.key} style={{backgroundColor:`${props.theme.background}` }}>
				<div className='card-plank--image-section'>
					<div className='card-plank--image-subsection'>
						<img src={`./images/${props.image}`} className='card-plank--image' />
					</div>
				</div>
				<div className='card-plank--content-section'>
					<div className='card-plank--top-section' style={{color:`${props.theme.cardFont}`, textShadow: "0 0 0px " + `${props.theme.cardFont}` }}>
						<span className='card-plank--title'>{props.title}</span>
						<p className='card-plank--description'>{props.description}</p>
					</div>
					<div className='card-plank--bottom-section'>
						<div className='card-plank--tech' onClick={changeStackLook}>
							{getStack}
						</div>
						<div className='card-plank--links'>
							{hasWeb &&
								<a className='card-plank--links-web card-plank--link' href={props.links.web} target="_blank" rel="noopener noreferrer">
									<img src={web} className='card-plank--link-icon' alt="Website"></img>
								</a>
							}
							{hasLocal &&
								<Link className='card-plank--links-web card-plank--link' to={props.links.local}>
									<img src={web} className='card-plank--link-icon' alt="Website"></img>
								</Link>
							}
							<a className='card-plank--links-code card-plank--link' href={props.links.code} target="_blank" rel="noopener noreferrer">
								<img src={github} className='card-plank--link-icon' alt="GitHub"></img>
							</a>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
