export function getInsertionSortAnimation(array) {
	const animation = [];
	insertionSort(array, animation);
	return animation;
}

function insertionSort(array, animation) {
    for (let i = 1; i < array.length; i++)
    {
        let key = array[i];
		animation.push(['pivot', i]);
        let j = i - 1;

        while (j >= 0 && array[j] > key)
        {
            array[j + 1] = array[j];
			animation.push(['swap',j]);
            j = j - 1;
        }

        array[j + 1] = key;
    }
}
