export default [
    {
        id: 1,
        title: "Animated Algorithms",
        description: "Animated sorting and pathfinding algorithms.",
        image: "sorting.gif",
		stack: ["JavaScript", "HTML", "CSS", "React"],
		links: {
			local: "/Algorithms",
			code: "https://github.com/rafaelmikayelyan/animated-algorithms"
		},
        published: true,
		theme: {
			background: "#727F90",
			cardFont: "#F8F4F7",
			stack: "#D4D3D4",
			stackFont: "#727F90"
		},
    },
    {
        id: 2,
        title: "Bootleg Pong",
        description: "Pong for Playdate console.",
        image: "pong.png",
		stack: ["Lua", "Playdate"],
		links: {
			web: "https://rafaelmikayelyan.itch.io/bootleg-pong",
			code: "https://github.com/rafaelmikayelyan/playdate-pong"
		},
        published: true,
		theme: {
			background: "#B4AEA7",
			cardFont: "#4A4640",
			stack: "#4A4640",
			stackFont: "#B4AEA7"
		},
    },
    {
        id: 3,
        title: "VGM Poster Downloader",
        description: "A CLI web scraper app for downloading inaccessible posters from VGM.",
        image: "print-dl.png",
		stack: ["Python", "OpenCV"],
		links: {
			code: "https://github.com/rafaelmikayelyan/dl-art-print-from-vgm"
		},
        published: false,
		theme: {
			background: "#1E1E1E",
			cardFont: "#FDFDFD",
			stack: "#C5C5C5",
			stackFont: "#1E1E1E"
		},
    }
]

