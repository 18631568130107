import { Outlet, Link } from "react-router-dom"

function Archive() {
	return (
		<>
		<div className="Archive">
			<div className="archive-container">
		<br />
		<center>***</center>
		<br />
				<h1 className="archive--title">test-UI</h1>
				<li className="archive--li"><Link to="/algorithms" className="archive--link">Algorithms</Link></li>
				<li className="archive--li"><Link to="/content" className="archive--link">Content - Lorem Ipsum</Link></li>
				<li className="archive--li"><Link to="/navbars" className="archive--link">NavBars - All Styles</Link></li>
				<li className="archive--li"><Link to="/projects" className="archive--link">Projects - Plank (current)</Link></li>
				<li className="archive--li"><Link to="/projects-pill" className="archive--link">Projects - Pill</Link></li>
				<li className="archive--li"><Link to="/projects-compact" className="archive--link">Projects - Compact</Link></li>
			</div>
		</div>
		<Outlet />
		</>
	);
}

export default Archive;
