import React from 'react'
import minImg from '../images/min.png'
import maxImg from '../images/max.png'
import {getBubbleSortAnimation} from './algorithms/bubbleSort';
import {getInsertionSortAnimation} from './algorithms/insertionSort';
import {getMergeSortAnimation} from './algorithms/mergeSort';
import {getSelectionSortAnimation} from './algorithms/selectionSort';
import {getQuicksortAnimation} from './algorithms/quicksort';
import {getDescription, getAlgorithmName} from './algorithms/algorithmsInfo';

const COLOR_DEFAULT = 'lightgrey';
const COLOR_CHOSEN = 'indianred';
const COLOR_CHECK = '#9fc5e8';
const COLOR_SWAP = '#8e7cc3';
const COLOR_USED = 'darkgrey';
const COLOR_COMPLETED = '#90cbaa';
const COLOR_BUTTON_DEFAULT = 'darkgrey';
const COLOR_BUTTON_SELECTED = 'indianred';

const ANIMATION_BAR_CHANGE = 200;

const RANGE_MIN = 10;
const RANGE_MAX = 100;
const RANGE_CURRENT = (RANGE_MAX - RANGE_MIN) / 2 + RANGE_MIN;

export default class SortingAlgorithms extends React.Component {
	constructor(props) {
		super(props);
		this.state = {array: [], selected: ''};
	}

	componentDidMount() {
		this.resetBars();
	}

	resetArray() {
		const array = [];
		for (let i = 0; i < document.getElementById("range").value; i++) {
			array.push(Math.floor(Math.random() * 400));
		}
		this.setState({array});
	}

	resetBars(param) {
		this.resetTimeout();
		this.resetArray();
		this.resetButtons(param);
		this.recolorBars(COLOR_DEFAULT);
		this.animationCounter = 0;
		this.isReady = true;
		this.changeDescription();
	}

	resetTimeout() {
		var id = window.setTimeout(function() {}, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	recolorBars(color) {
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < bars.length; i++) {
			bars[i].style.backgroundColor = color;
		}
	}

	markGraphAsCompleted() {
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < bars.length; i++) {
			setTimeout(() => {
				bars[i].style.backgroundColor = COLOR_COMPLETED;
			}, i * 50);
		}
	}

	resetButtons(selected) {
		const buttons = document.getElementsByClassName('button--sort');
		for (let i = 0; i < buttons.length; i++) {
			buttons[i].style.backgroundColor = COLOR_BUTTON_DEFAULT;
			buttons[i].innerHTML = getAlgorithmName(buttons[i].id);
		}
		if (selected) {
			document.getElementById(selected).style.backgroundColor = COLOR_BUTTON_SELECTED;
		}
	}

	bubbleSort() {
		const animation = getBubbleSortAnimation(this.state.array);
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < animation.length; i++) {
			const frame = animation[i];
			this.animationCounter += 1;			
			let bar0 = null;
			if (frame[1] > 0) {
				bar0 = bars[frame[1] - 1].style;
			}
			const bar1 = bars[frame[1]].style;
			const bar2 = bars[frame[2]].style;
			setTimeout(() => {
				if (frame[0] === 'check') {
					if (bar0) {
						bar0.backgroundColor = COLOR_DEFAULT;
					}
					bar1.backgroundColor = bar2.backgroundColor = COLOR_CHECK;
				} else if (frame[0] === 'swap') {
					if (bar0) {
						bar0.backgroundColor = COLOR_DEFAULT;
					}
					bar1.backgroundColor = bar2.backgroundColor = COLOR_SWAP;
					[bar1.height, bar2.height] = [bar2.height, bar1.height];
				} else if (frame[0] === 'done') {
					for (let i = 0; i < bars.length; i++) {
						if (bars[i].style.backgroundColor !== COLOR_DEFAULT) {
							bars[i].style.backgroundColor = COLOR_USED;
						}
					}
				}
			}, i * (this.animationSpeed()));
		}
	}

	insertionSort() {
		const animation = getInsertionSortAnimation(this.state.array);
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < animation.length; i++) {
			const frame = animation[i];
			this.animationCounter += 1;			
			const bar = bars[frame[1]].style;
			setTimeout(() => {
				if (frame[0] === 'pivot') {
					bar.backgroundColor = COLOR_CHECK;
					for (let j = 0; j < frame[1]; j++) {
						bars[j].style.backgroundColor = COLOR_SWAP;
					}
				} else if (frame[0] === 'swap') {
					bar.backgroundColor = COLOR_SWAP;
					[bar.height, bars[frame[1] + 1].style.height] = [bars[frame[1] + 1].style.height, bar.height];
				}
			}, i * (this.animationSpeed()));
		}
	}

	mergeSort() {
		const animation = getMergeSortAnimation(this.state.array);
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < animation.length; i++) {
			const frame = animation[i];
			this.animationCounter += 1;			
			setTimeout(() => {
				if (frame[0] === 'area') {
					this.recolorBars(COLOR_DEFAULT);
					for (let j = frame[1][0]; j < frame[1][1]; j++) {
						bars[j].style.backgroundColor = COLOR_USED;
					}
				} else if (frame[0] === 'change') {
					for (let j = 0; j < bars.length; j++) {
						if (bars[j].style.backgroundColor === "rgb(142, 124, 195)") {
							bars[j].style.backgroundColor = COLOR_USED;
						}
					}
					bars[frame[1]].style.height = frame[2] + 'px';
					bars[frame[1]].style.backgroundColor = COLOR_SWAP;
				} else if (frame[0] === 'swap') {
					for (let j = 0; j < bars.length; j++) {
						if (bars[j].style.backgroundColor === "rgb(142, 124, 195)") {
							bars[j].style.backgroundColor = COLOR_USED;
						}
					}
					const bar1 = bars[frame[1]].style;
					const bar2 = bars[frame[2]].style;
					[bar1.height, bar2.height] = [bar2.height, bar1.height];
					bar1.backgroundColor = bar2.backgroundColor = COLOR_SWAP;
				} else if (frame[0] === 'merge') {
					for (let j = 0; j < bars.length; j++) {
						if (bars[j].style.backgroundColor !== COLOR_DEFAULT) {
							bars[j].style.backgroundColor = COLOR_USED;
						}
					}
					for (let j = 0; j < frame[2].length; j++) {
						bars[frame[1] + j].style.height = frame[2][j] + 'px';
						bars[frame[1] + j].style.backgroundColor = COLOR_CHOSEN;
					}
				}
			}, i * (this.animationSpeed()));
		}
	}

	selectionSort() {
		const animation = getSelectionSortAnimation(this.state.array);
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < animation.length; i++) {
			const frame = animation[i];
			this.animationCounter += 1;			
			const bar = bars[frame[1]].style;
			setTimeout(() => {
				if (frame[0] === 'start') {
					this.recolorBars(COLOR_DEFAULT)
					bar.backgroundColor = COLOR_USED;
				}
				if (frame[0] === 'pivot') {
					for (let i = 0; i < bars.length; i++) {
						if (i !== frame[1] && bars[i].style.backgroundColor !== COLOR_USED) {
							bars[i].style.backgroundColor = COLOR_DEFAULT;
						}
					}
					bar.backgroundColor = COLOR_CHOSEN;
				} else if (frame[0] === 'check') {
					for (let i = 0; i < bars.length; i++) {
						if (bars[i].style.backgroundColor !== COLOR_CHOSEN && bars[i].style.backgroundColor !== COLOR_USED) {
							bars[i].style.backgroundColor = COLOR_DEFAULT;
						}
					}
					bar.backgroundColor = COLOR_CHECK;
				} else if (frame[0] === 'swap') {
					this.recolorBars(COLOR_DEFAULT)
					const bar2 = bars[frame[2]].style;
					bar.backgroundColor = bar2.backgroundColor = COLOR_SWAP;
					[bar.height, bar2.height] = [bar2.height, bar.height];
				}
			}, i * (this.animationSpeed()));
		}
	}

	quicksort() {
		const animation = getQuicksortAnimation(this.state.array);
		const bars = document.getElementsByClassName('bar');
		for (let i = 0; i < animation.length; i++) {
			const frame = animation[i];
			this.animationCounter += 1;			
			const bar1 = bars[frame[1]].style;
			const bar2 = bars[frame[2]].style;
			setTimeout(() => {
				if (frame[0] === 'pivot') {
					this.recolorBars(COLOR_DEFAULT);
					bar1.backgroundColor = COLOR_CHECK;
				} else if (frame[0] === 'swap') {
					bar1.backgroundColor = COLOR_SWAP;
					bar2.backgroundColor = COLOR_CHOSEN;
					[bar1.height, bar2.height] = [bar2.height, bar1.height];
				} else if (frame[0] === 'partition') {
					for (let i = frame[1]; i < frame[2]; i++) {
						if (bars[i].style.backgroundColor !== 'rgb(100, 149, 237)') {
							bars[i].style.backgroundColor = COLOR_USED;
						}
					}
				}
			}, i * (this.animationSpeed()));
		}
	}

	changeDescriptionOnHover = (event) => {
		let id;
		if (event.type === 'mouseenter') {
			id = event.target.id;
		} else if (event.type === 'mouseleave') {
			id = this.state.selected;
		}
		document.getElementById('description').innerHTML = getDescription(id);
	}

	changeDescription(id = null) {
		this.state.selected = id;
		document.getElementById('description').innerHTML = getDescription(id);
	}

	animationSpeed() {
		return 1000 / this.state.array.length + ANIMATION_BAR_CHANGE;
	}

	runSort = (event) => {
		const id = (event.target.id);
		if (!this.isReady || document.getElementById(id).className === 'button--disabled') {
			return;
		}
		this.resetButtons(id);
		this.isReady = false;
		this.changeDescription(id);
		if (id === 'button--bubble') {
			this.bubbleSort();
		} else if (id === 'button--insertion') {
			this.insertionSort();
		} else if (id === 'button--merge') {
			this.mergeSort();
		} else if (id === 'button--selection') {
			this.selectionSort();
		} else if (id === 'button--quicksort') {
			this.quicksort();
		}
		setTimeout(() => {
			this.markGraphAsCompleted();
		}, this.animationCounter * this.animationSpeed());
	}

	render() {
		const {array} = this.state;

		return (

		<div className="Algorithms">
			<div className='sorting-graph-container'>
				{array.map((value, idx) => (
					<div className="bar" key={idx}
						style={{ backgroundColor: COLOR_DEFAULT, height: `${value}px`, }}>
					</div>
				))}
			</div>
			<div className='controls'>
				<div className='section--sort'>
					<button id='button--bubble' className='button--algo button--sort' onClick={this.runSort} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}></button>
					<button id='button--insertion' className='button--algo button--sort' onClick={this.runSort} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}></button>
					<button id='button--merge' className='button--algo button--sort' onClick={this.runSort} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}></button>
					<button id='button--selection' className='button--algo button--sort' onClick={this.runSort} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}></button>
					<button id='button--quicksort' className='button--algo button--sort' onClick={this.runSort} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}></button>
				</div>
				<div className='section--generate'>
					<button id="button--refresh" className='button--algo button--refresh'onClick={() => this.resetBars()}></button>
					<div className="slider-container">
						<img src={minImg} alt='Less bars to sort' id='slider-min' className='slider-icons'></img>
						<input type="range" min={RANGE_MIN} max={RANGE_MAX} defaultValue={RANGE_CURRENT} className="slider" id="range" onChange={() => this.resetBars()}/>
						<img src={maxImg} alt='More bars to sort' id='slider-max' className='slider-icons'></img>
					</div>
				</div>
				<div className='section--description'>
				<br />
					<div id='description'></div>
				</div>
			</div>
			
		</div>
		);
	}
}
