import { Outlet, Link } from "react-router-dom"
import email from '../images/email.png'
import github from '../images/github.png'
import linkedin from '../images/linkedin.png'

function Navbar() {
	return (
		<>
		<nav>
			<div className="nav-centured-container">
				<div className="nav-centured--top-section">
					<Link className="nav-centured--webname" to="/">RAFAEL MIKAYELYAN</Link>
				</div>
				<div className="nav-centured--bottom-section">
					<Link to="/Projects" className="nav-centured--button">Projects</Link>
					<Link to="/Archive" className="nav-centured--button">Archive</Link>
					<div className="nav-centured--icons">
						<a href="mailto:rafaelmikayelyan.web@gmail.com"  target="_blank" rel="noopener noreferrer" className="nav-centured--button">
							<img src={email} className="nav-centured--button-icon" alt="Email"></img>
						</a>
						<a href="https://github.com/rafaelmikayelyan" target="_blank" rel="noopener noreferrer">
							<img src={github} className="nav-centured--button-icon" alt="GitHub"></img>
						</a>
						<a href="https://www.linkedin.com/in/rafael-mikayelyan/" target="_blank" rel="noopener noreferrer">
							<img src={linkedin} alt="LinkedIn" className="nav-centured--button-icon"></img>
						</a>
					</div>
				</div>
			</div>
		</nav>
		<Outlet />
		</>
	);
}

export default Navbar;
