import { Outlet, Link } from "react-router-dom"
import email from '../images/email.png'
import github from '../images/github.png'
import linkedin from '../images/linkedin.png'
import { useState } from 'react'

function Navbar() {
	const [path, setPath] = useState('~');

	const updatePath = event => {
		setPath(event.target.innerHTML)
	}

	const setRootPath = () => {
		setPath("~")
	}

	return (
		<>
		<div className="NavbarCLI">
			<div className="nav-cli-container">
				<div className="nav-cli--top-section">
					<Link className="webname-cli" to="/" onClick={setRootPath}>rafael_mikayelyan@dev <span className="cli-path">{path}</span> % cd</Link>
					<span className="nav-cli--cursor">&nbsp;</span>
				</div>
				<div className="nav-cli--bottom-section">
					<div className="nav-cli--goto">
						<Link to="/Projects" className="nav-cli--button" onClick={updatePath}>projects</Link>
						<Link to="/Archive" className="nav-cli--button" onClick={updatePath}>archive</Link>
					</div>
					<div className="nav-cli--icons">
						<a href="mailto:rafaelmikayelyan.web@gmail.com"  target="_blank" rel="noopener noreferrer">
							<img src={email} className="nav-cli--button-icon" alt="Email"></img>
						</a>
						<a href="https://github.com/rafaelmikayelyan" target="_blank" rel="noopener noreferrer">
							<img src={github} className="nav-cli--button-icon" alt="GitHub"></img>
						</a>
						<a href="https://www.linkedin.com/in/rafael-mikayelyan/" target="_blank" rel="noopener noreferrer">
							<img src={linkedin} alt="LinkedIn" className="nav-cli--button-icon"></img>
						</a>
					</div>

				</div>
			</div>
		</div>
		<Outlet />
		</>
	);
}

export default Navbar;
