export function getBubbleSortAnimation(array) {
	const animation = [];
	bubbleSort(array, animation)
	return animation;
}

function bubbleSort(array, animation) {
	for (let i = 0; i < array.length - 1; i++) {
		for (let j = 0; j < array.length - i - 1; j++) {
			animation.push(['check', j, j+1]);
			if (array[j] > array[j + 1]) {
				let temp = array[j];
				array[j] = array[j + 1];
				array[j + 1] = temp;
				animation.push(['swap', j, j+1]);
			}
		}
		animation.push(['done', i, i]);
	}
}
