export function getSelectionSortAnimation(array) {
	const animation = [];
	selectionSort(array, animation);
	return animation;
}

function selectionSort(array, animation) {
    for (let i = 0; i < array.length - 1; i++) {
		animation.push(['start', i]);
        let min = i;

        for (let j = i + 1; j < array.length; j++) {
			animation.push(['check', j]);
			if (array[j] < array[min]) {
				min = j;
				animation.push(['pivot', j]);
			}
		}

		let temp = array[min];
		array[min] = array[i];
		array[i] = temp;
		animation.push(['swap', min, i]);
	}
}
