import Sorting from "./SortingAlgorithms"
import Pathfinding from "./PathfindingAlgorithms"
import React, { useState } from 'react';

export default function Algorithms() {
	const [showAlgo, setShowAlgo] = useState(true);

	const toggleSort = () => {
		setShowAlgo(true);
	};

	const togglePath = () => {
		setShowAlgo(false);
	};

	return (
		<div className="Algorithms">
			<h1 className="algorithms--title">Algorithms</h1>
			<div className="algorithms--button-container">
				<button className={`${showAlgo ? "algorithms--button-active" : "algorithms--button-idle"}`} onClick={toggleSort}>Sorting</button>
				<button className={`${showAlgo ? "algorithms--button-idle" : "algorithms--button-active"}`} onClick={togglePath}>Pathfinding</button>
			</div>
			<div className="algorithms--container">
				{showAlgo && <Sorting />}
				{!showAlgo && <Pathfinding />}
			</div>
		</div>
	);
}

// export default Algorithms;
