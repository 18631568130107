import duckImg from '../images/duck-white.png';

export default function SettingsContent() {
	return (
		<div className="settings-content-container">
			<h1 className="settings-content--title">&lt;HelloWorld /&gt;</h1>
			<div className="settings-content--text">
				<p>...don&lsquo;t know what to do here yet...</p>
			</div>
			<img className="settings-content--img" src={duckImg} />
		</div>
	);
}
