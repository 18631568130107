import { BrowserRouter, Routes, Route, Switch, withRouter } from "react-router-dom"
import Content from "./components/Content"
import ProjectsPill from "./components/ProjectsPill"
import ProjectsPlank from "./components/ProjectsPlank"
import ProjectsCompact from "./components/ProjectsCompact"
import Algorithms from "./components/Algorithms"
import Archive from "./components/Archive"
import GoUp from "./components/GoUp"
import Settings from "./components/Settings"
import NavBars from "./components/NavBars"
import NavbarCentered from "./components/NavbarCentered"
import NavbarCLI from "./components/NavbarCLI"
import NavbarMinimal from "./components/NavbarMinimal"

function App() {
	return (
		<div className="App">
			<BrowserRouter basename={`/${process.env.PUBLIC_URL}`}>
			<Routes>
				<Route path="/" element={<NavbarMinimal />}>
					<Route index element={<ProjectsPlank />} />
					<Route path="projects" element={<ProjectsPlank />} />
					<Route path="archive" element={<Archive />} />
					<Route path="algorithms" element={<Algorithms />} />
					<Route path="*" element={<Archive />} />
					<Route path='content' element={<Content />} />
					<Route path='projects-pill' element={<ProjectsPill />} />
					<Route path='projects-compact' element={<ProjectsCompact />} />
					<Route path='navbars' element={<NavBars />} />
					<Route path='navbar-centered' element={<NavbarCentered />} />
					<Route path='navbar-cli' element={<NavbarCLI />} />
				</Route>
			</Routes>
			</BrowserRouter>
			<Settings />
			<GoUp />
		</div>
	);
}

export default App;
