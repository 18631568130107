import { useState } from 'react'
import { Outlet, Link } from "react-router-dom"
import web from '../images/web-black.png'
import github from '../images/code-black.png'
export default function Card(props) {
	let hasWeb
	let hasLocal
	const [stackImg, setStackImg] = useState(false);

	if (props.links.web) {
		hasWeb = true
	} else {
		hasWeb = false
	}

	if (props.links.local) {
		hasLocal = true
	} else {
		hasLocal = false
	}

	let stack = props.stack
	const getStack = stack.map(item => {
		if (stackImg) {
			return (
				// <span className='card--tech-lang card--tech-item'>{`${item}`}</span>
				<img src={`./images/stack/${item}.png`} className='card--tech-item-image' />
			);
		} else { 
			return <span className='card--tech-lang card--tech-item' style={{backgroundColor:`${props.theme.stack}`, color:`${props.theme.stackFont}`}}>{`${item}`}</span>;
		}
	});



	const changeStackLook = () => {
		setStackImg(stackImg? false : true);
	}

	return (
		<div className='Card' style={{backgroundColor:`${props.theme.background}` }}>
			<div className='card--image-section'>
				<img src={`./images/${props.image}`} className='card--image' />
			</div>
			<div className='card--content-section'>
				<div className='card--top-section' style={{color:`${props.theme.cardFont}` }}>
					<span className='card--title'>{props.title}</span>
					<p className='card--description'>{props.description}</p>
				</div>
				<div className='card--bottom-section'>
					<div className='card--tech' onClick={changeStackLook}>
						{getStack}
					</div>
					<div className='card--links'>
						{hasWeb &&
							<a className='card--links-web card--link' href={props.links.web} target="_blank" rel="noopener noreferrer">
								<img src={web} className='card--icon' alt="Website"></img>
							</a>
						}
						{hasLocal &&
							<Link className='card--links-web card--link' to={props.links.local}>
								<img src={web} className='card--icon' alt="Website"></img>
							</Link>
						}
						<a className='card--links-code card--link' href={props.links.code} target="_blank" rel="noopener noreferrer">
							<img src={github} className='card--icon' alt="GitHub"></img>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
