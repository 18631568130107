import NavbarCentered from "./NavbarCentered"
import NavbarwMinimal from "./NavbarMinimal"
import NavbarCLI from "./NavbarCLI"

export default function NavBars() {
	return (
		<div className="NavBars">
			<NavbarCentered />
			<NavbarwMinimal />
			<NavbarCLI />
		</div>
	);
}


