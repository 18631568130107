import { Outlet, Link } from "react-router-dom"
import { useState, useEffect } from "react"
import email from '../images/envelope-black.png'
import github from '../images/github-black.png'
import linkedin from '../images/linkedin-black.png'
import more from '../images/more-black.png'
import exit from '../images/x-black.png'

function Navbar() {
	const [showMore, setMore] = useState(false);
	const [isMobile, setMobile] = useState(window.innerWidth <= 720? true : false);
	
	useEffect(() => {
		setMobile(window.innerWidth <= 720? true : false)
	}, [])

	useEffect(() => {
		function handleResize() {
			setMobile(window.innerWidth <= 720? true : false);
			setMore(false);
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const toggleMore = () => {
		setMore(showMore? false : true);
		console.log(showMore);
	};
	return (
		<>
		<nav>
		<div className="NavbarMinimal">
			<div className="nav-minimal--container">
				<div className="nav-minimal--title-section">
					<Link className="nav-minimal--title" to="/">RAFAEL MIKAYELYAN</Link>
				</div>
				{isMobile && (<button className="nav-minimal--button--more" onClick={toggleMore}>
						<img src={more} className="nav-minimal--button--more-icon" alt="More" />
					</button>)}
				{!isMobile && (
				<div className="nav-minimal--links-section">
					<Link to="/" className="nav-minimal--page-link">PROJECTS</Link>
					<Link to="/Archive" className="nav-minimal--page-link">ARCHIVE</Link>
					<div className="nav-minimal--icons">
						<a href="mailto:rafaelmikayelyan.web@gmail.com"  target="_blank" rel="noopener noreferrer">
							<img src={email} className="nav-minimal--icon" alt="Email" />
						</a>
						<a href="https://github.com/rafaelmikayelyan" target="_blank" rel="noopener noreferrer">
							<img src={github} className="nav-minimal--icon" alt="GitHub" />
						</a>
						<a href="https://www.linkedin.com/in/rafael-mikayelyan/" target="_blank" rel="noopener noreferrer">
							<img src={linkedin} alt="LinkedIn" className="nav-minimal--icon" />
						</a>
					</div>
				</div>
				)}
			</div>
			{isMobile && showMore && (
				<div className="nav-minimal--more-section">
					<div className="nav-minimal--more-top">
						<Link className="nav-minimal--title" to="/" onClick={toggleMore}>RAFAEL MIKAYELYAN</Link>
						<button className="nav-minimal--button--exit" onClick={toggleMore}>
							<img src={exit} className="nav-minimal--button--exit-icon" alt="Exit" />
						</button>
					</div>
					<div className="nav-minimal--more-center">
						<Link to="/" className="nav-minimal--page-link" onClick={toggleMore}>PROJECTS</Link>
						<Link to="/Archive" className="nav-minimal--page-link" onClick={toggleMore}>ARCHIVE</Link>
					</div>
					<div className="nav-minimal--more-icons">
						<a href="mailto:rafaelmikayelyan.web@gmail.com"  target="_blank" rel="noopener noreferrer">
							<img src={email} className="nav-minimal--icon" alt="Email" />
						</a>
						<a href="https://github.com/rafaelmikayelyan" target="_blank" rel="noopener noreferrer">
							<img src={github} className="nav-minimal--icon" alt="GitHub" />
						</a>
						<a href="https://www.linkedin.com/in/rafael-mikayelyan/" target="_blank" rel="noopener noreferrer">
							<img src={linkedin} alt="LinkedIn" className="nav-minimal--icon" />
						</a>
					</div>
				</div>
			)}
		</div>
		</nav>
		<Outlet />
		</>
	);
}

export default Navbar;
