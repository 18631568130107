import React, { useState, useEffect } from 'react'
import Content from './SettingsContent'
import gear from '../images/gear-black.png'
import exit from '../images/x-white.png'

export default function Settings() {
	const [showSettings, setShowSettings] = useState(false);

	const toggleSettings = () => {
		setShowSettings(showSettings? false : true);
	};

	return (
		<div className="settings-container">
			<button className="settings--button--enter" onClick={toggleSettings}>
				<img src={gear} className="settings--button--icon" alt="Settings" />
			</button>
			{showSettings && (<div className="settings-rim" onClick={toggleSettings}></div>)}
			{showSettings && (<div className="settings">
				<Content />
				<button className="settings--button--exit" onClick={toggleSettings}>
					<img src={exit} className="settings--button--icon" alt="Exit" />
				</button></div>)}
		</div>
	);
}
