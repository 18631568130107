import React, { useState, useEffect } from 'react'
import goup from '../images/up-white.png'

export default function GoUp() {
	const [showGoUpButton, setShowGoUpButton] = useState(false);

	useEffect(() => {
		window.addEventListener('scroll', () => {
			if (window.scrollY > 300) {
				setShowGoUpButton(true);
			} else {
				setShowGoUpButton(false);
			}
		});
	}, []);

	const goUp = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (
		<div className="go-up-container">
			{showGoUpButton && (<button className="go-up--button" onClick={goUp}>
				<img src={goup} className="go-up--button--icon" alt="GoUp" />
			</button>)}
		</div>
	);
}
