import { useState, useEffect } from 'react'
import { Outlet, Link } from "react-router-dom"
import web from '../images/web-black.png'
import github from '../images/code-black.png'
export default function Card(props) {
	let hasWeb
	let hasLocal
	const [showContent, setShowContent] = useState(false);
	const [isMobile, setMobile] = useState(window.innerWidth <= 720? true : false);
	
	useEffect(() => {
		setMobile(window.innerWidth <= 720? true : false)
	}, [])

	useEffect(() => {
		function handleResize() {
			setMobile(window.innerWidth <= 720? true : false);
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	if (props.links.web) {
		hasWeb = true
	} else {
		hasWeb = false
	}

	if (props.links.local) {
		hasLocal = true
	} else {
		hasLocal = false
	}

	let stack = props.stack
	const getStack = stack.map(item => {
		return (
			<img src={`./images/stack/${item}.png`} className='card-compact--tech-item-image' />
		)
	});



	const changeShowContent = () => {
		setShowContent(showContent? false : true);
	}

	return (
		<div className='card-compact' style={{backgroundColor:`${props.theme.background}` }}>
			<div className='card-compact--image-section' onClick={changeShowContent}>
				<img src={`./images/${props.image}`} className='card-compact--image' />
			</div>
			{((isMobile && showContent) || (!isMobile)) && (
				<div className='card-compact--content-section'>
					<div className='card-compact--top-section' style={{color:`${props.theme.cardFont}` }}>
						<span className='card-compact--title'>{props.title}</span>
						<p className='card-compact--description'>{props.description}</p>
					</div>
					<div className='card-compact--bottom-section'>
						<div className='card-compact--tech'>
							{getStack}
						</div>
						<div className='card-compact--links'>
							{hasWeb &&
								<a className='card-compact--links-web card--link' href={props.links.web} target="_blank" rel="noopener noreferrer">
									<img src={web} className='card-compact--icon' alt="Website"></img>
								</a>
							}
							{hasLocal &&
								<Link className='card-compact--links-web card--link' to={props.links.local}>
									<img src={web} className='card-compact--icon' alt="Website"></img>
								</Link>
							}
							<a className='card-compact--links-code card--link' href={props.links.code} target="_blank" rel="noopener noreferrer">
								<img src={github} className='card-compact--icon' alt="GitHub"></img>
							</a>
						</div>
					</div>
				</div>
			)}

		</div>
	);
}
