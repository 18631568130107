export function getMergeSortAnimation(array) {
	const clone = array.slice();
	const animation = [];
	mergeSort(clone, [0, clone.length], animation);
	return animation;
}

function mergeHalves(leftArray, rightArray, area, animation, middleIndex) {
	let tempArray = [];
	let counterLeft = 0;
	let counterRight = 0;
	let counterIndex = 0;
	let sequence = [];
	animation.push(['area', area]);
    while (leftArray.length && rightArray.length) {
		if (leftArray[0] < rightArray[0]) {
			tempArray.push(leftArray.shift());
			sequence.push(area[0] + counterLeft);
			counterLeft++;
        } else {
            tempArray.push(rightArray.shift());
			sequence.push(middleIndex + counterRight);
			counterLeft++;
			counterRight++;
        }
		animation.push(['change', area[0] + counterIndex, tempArray[tempArray.length - 1]]);
		counterIndex++;
    }

	const leftoverArray = [...leftArray, ...rightArray];

	// if (tempArray.length > 1) {
	// 	for (let i = 0; i < tempArray.length; i++) {
	// 		animation.push(['swap', area[0] + i, sequence[i]]);
	// 	}
	// } else {
	// 	animation.push(['change', area[0], tempArray[0]]);
	// }

	if (leftoverArray.length > 0) {
		animation.push(['merge', area[1] - leftoverArray.length, leftoverArray]);
	}
	return [ ...tempArray, ...leftoverArray ];
}

function mergeSort(array, area, animation) {
	const middleIndex = Math.floor(array.length / 2);
	if(array.length < 2) {
		return array;
	}
	const leftArray = array.splice(0, middleIndex);
	return mergeHalves(mergeSort(leftArray, [area[0], area[0] + middleIndex], animation), mergeSort(array, [area[1] - 1 - middleIndex, area[1]], animation), area, animation, middleIndex);
}
