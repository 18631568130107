import React, {Component} from 'react';
import Node from './Node';
import {dijkstra, getNodesInShortestPathOrder} from './algorithms/dijkstra';
import {getDescription} from './algorithms/algorithmsInfo';

const START_NODE_ROW = 5;
const START_NODE_COL = 5;
const FINISH_NODE_ROW = 10;
const FINISH_NODE_COL = 10;

const COLOR_BUTTON_DEFAULT = 'darkgrey';
const COLOR_BUTTON_SELECTED = 'indianred';

export default class PathfindingAlorithms extends Component {
	constructor() {
		super();
		this.state = {grid: [], mouseIsPressed: false, selected: ''};
	}

	componentDidMount() {
		this.resetGrid();
		// this.disable();
	}

	disable() {
		document.getElementsByClassName('section--description')[0].style.display = 'none';
		document.getElementsByClassName('controls')[0].style.display = 'none';
	}

	resetGrid(param) {
		// cleanGrid();
		this.resetTimeout();
		this.resetButtons(param);
		this.isReady = true;
		this.changeDescription();
		const grid = getInitialGrid();
		this.setState({grid});
	}

	resetTimeout() {
		var id = window.setTimeout(function() {}, 0);
		while (id--) {
			window.clearTimeout(id);
		}
	}

	resetButtons(selected) {
		const buttons = document.getElementsByClassName('button--path');
		for (let i = 0; i < buttons.length; i++) {
			buttons[i].style.backgroundColor = COLOR_BUTTON_DEFAULT;
		}
		if (selected) {
			document.getElementById(selected).style.backgroundColor = COLOR_BUTTON_SELECTED;
		}
	}

	handleMouseDown(row, col) {
		const newGrid = getNewGridWithWallToggled(this.state.grid, row, col);
		this.setState({grid: newGrid, mouseIsPressed: true});
	}

	handleMouseEnter(row, col) {
		if (!this.state.mouseIsPressed) return;
		const newGrid = getNewGridWithWallToggled(this.state.grid, row, col);
		this.setState({grid: newGrid});
	}

	handleMouseUp() {
		this.setState({mouseIsPressed: false});
	}

	animateDijkstra(visitedNodesInOrder, nodesInShortestPathOrder) {
		for (let i = 0; i <= visitedNodesInOrder.length; i++) {
			if (i === visitedNodesInOrder.length) {
				setTimeout(() => {
					this.animateShortestPath(nodesInShortestPathOrder);
				}, 10 * i);
				return;
			}
			setTimeout(() => {
				const node = visitedNodesInOrder[i];
				document.getElementById(`node-${node.row}-${node.col}`).className = 'node node-visited';
			}, 10 * i);
		}
	}

	animateShortestPath(nodesInShortestPathOrder) {
		for (let i = 0; i < nodesInShortestPathOrder.length; i++) {
			setTimeout(() => {
				const node = nodesInShortestPathOrder[i];
				document.getElementById(`node-${node.row}-${node.col}`).className = 'node node-shortest-path';
			}, 50 * i);
		}
	}


	dijkstra() {
		const {grid} = this.state;
		const startNode = grid[START_NODE_ROW][START_NODE_COL];
		const finishNode = grid[FINISH_NODE_ROW][FINISH_NODE_COL];
		const visitedNodesInOrder = dijkstra(grid, startNode, finishNode);
		const nodesInShortestPathOrder = getNodesInShortestPathOrder(finishNode);
		this.animateDijkstra(visitedNodesInOrder, nodesInShortestPathOrder);
	}

	changeDescriptionOnHover = (event) => {
		let id;
		if (event.type === 'mouseenter') {
			id = event.target.id;
		} else if (event.type === 'mouseleave') {
			id = this.state.selected;
		}
		document.getElementById('description').innerHTML = getDescription(id);
	}

	changeDescription(id = null) {
		this.state.selected = id;
		document.getElementById('description').innerHTML = getDescription(id);
	}

	runPath = (event) => {
		const id = event.target.id;
		if (!this.isReady || document.getElementById(id).className === 'button--disabled') {
			return;
		}
		this.resetButtons(id);
		this.isReady = false;
		this.changeDescription(id);
		if (id === 'button--dijkstra') {
			this.dijkstra();
		// } else if (id === 'button--insertion') {
		// 	this.insertionSort();
		// } else if (id === 'button--merge') {
		// 	this.mergeSort();
		// } else if (id === 'button--selection') {
		// 	this.selectionSort();
		// } else if (id === 'button--quicksort') {
		// 	this.quicksort();
		}
	}
	
	render() {
		const {grid, mouseIsPressed} = this.state;

		return (
		<div className='Algorithms'>
			<h1 className="yellow-neon-sign">UNDER reCONSTRUCTION</h1>
			<div className='pathfinding-grid-container'>
				{grid.map((row, rowIdx) => {
					return (
						<div key={rowIdx} className='pathfinding-grid-row'>
							{row.map((node, nodeIdx) => {
								const {row, col, isFinish, isStart, isWall} = node;
								return (
									<Node
										key={nodeIdx}
										col={col}
										isFinish={isFinish}
										isStart={isStart}
										isWall={isWall}
										mouseIsPressed={mouseIsPressed}
										onMouseDown={(row, col) => this.handleMouseDown(row, col)}
										onMouseEnter={(row, col) =>
											this.handleMouseEnter(row, col)
										}
										onMouseUp={() => this.handleMouseUp()}
										row={row}>
									</Node>
								);
							})}
						</div>
					);
				})}
			</div>
			<div className='controls'>
				<div className='section--generate'>
					<button id="button--refresh" className='button--algo button--refresh'onClick={() => this.resetGrid()}></button>
					<span style={{color: 'white', fontFamily: 'monospace', fontWeight: '600', fontSize: '1rem'}}>&nbsp;generate:&nbsp;</span>
					<button id="button--maze" className='button--algo button--maze' onClick={() => this.generateMaze()}>maze</button>
				</div>
				<div className='section--sort'>
					<button id='button--dijkstra' className='button--algo button--path' onClick={this.runPath} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}>Dijkstra</button>
					<button id='blank' className='button--algo button--path' onClick={this.runPath} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}>null</button>
					<button id='blank' className='button--algo button--path' onClick={this.cleanGrid} onMouseEnter={this.changeDescriptionOnHover} onMouseLeave={this.changeDescriptionOnHover}>null</button>
				</div>
			</div>
			<div className='section--description'>
				<p id='description'></p>
			</div>
		</div>
		);
	}
}

function calculateGrid() {
	const width = window.innerWidth;
	let rows = 20;
	let cols = 30;

	if (width < 800) {
		cols = width / 30;
	}

	return [rows, cols];
};

const getInitialGrid = () => {
	// cleanGrid();
	const grid = [];
	
	const [rows, cols] = calculateGrid();
		
	for (let row = 0; row < rows; row++) {
		const currentRow = [];
		for (let col = 0; col < cols; col++) {
			currentRow.push(createNode(col, row));
		}
		grid.push(currentRow);
	}
	return grid;
};

const createNode = (col, row) => {
	return {
		col,
		row,
		isStart: row === START_NODE_ROW && col === START_NODE_COL,
		isFinish: row === FINISH_NODE_ROW && col === FINISH_NODE_COL,
		distance: Infinity,
		isVisited: false,
		isWall: false,
		previousNode: null,
	};
};

const getNewGridWithWallToggled = (grid, row, col) => {
	const newGrid = grid.slice();
	const node = newGrid[row][col];
	const newNode = {...node, isWall: !node.isWall, };
	newGrid[row][col] = newNode;
	return newGrid;
};

function cleanGrid() {
	const nodes = document.getElementsByClassName('node');
	for (let i = 0; i < nodes.length; i++) {
		console.log(nodes[i]);
		nodes[i].className = 'node';
		// nodes[i].style.backgroundColor = 'white';
	}
}

